import React from "react"
import axios from "axios"
import * as qs from "query-string"

import FormEmbed from "./formembed"

class NewsletterGen extends React.Component {
 constructor(props) {
  super(props)
  this.domRef = React.createRef()
  this.state = { feedbackMsg: null }
 }

 handleSubmit(event) {
  event.preventDefault();

  // Use FormData to capture all form fields, including g-recaptcha-response
  const formData = new FormData(event.target);

  // Axios options
  const axiosOptions = {
    url: "/newsletter/",
    method: "post",
    headers: { "Content-Type": "multipart/form-data" },
    data: formData,
  };

  // Submit to Netlify
  axios(axiosOptions)
    .then(response => {
      this.setState({
        feedbackMsg: "Thank you for signing up to the BBA newsletter!",
      });
      let formSubmit = document.getElementById("lg");
      formSubmit.setAttribute('style', 'display:none');
    })
    .catch(err => {
      this.setState({
        feedbackMsg: "Form could not be submitted.",
      });
    });
}


  render() {
    return (
	    <FormEmbed>
		
       <center><div className="feedback">{this.state.feedbackMsg && <p>{this.state.feedbackMsg}</p>}</div></center>

        <form ref={this.domRef} name="newsletter" method="POST" data-netlify="true" id="lg" netlify-honeypot="bot-field" onSubmit={event => this.handleSubmit(event)}>
          <input ref="form-name" type="hidden" name="form-name" value="newsletter" />
  <center>  
  <p>
    <span><input type="text" className="sub-form-input-newsletter" placeholder="First Name *" ref="first-name" name="first-name" required /> <input type="text" className="sub-form-input-newsletter" placeholder="Last Name *" ref="last-name" name="last-name"  required/></span>
  </p>
  <p>
    <span><input type="email" name="bot-field" ref="bot-field" className="hide-me" /><input type="email" className="sub-form-input-newsletter" placeholder="Email *" ref="email" name="email" required /> <button className="btn-new" type="submit">Submit</button></span>
  </p>
  </center>
        </form>
	</FormEmbed>	
    )
  }
}

export default NewsletterGen