import React, { useState } from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import NewsletterGen from "../components/newslettergen"
import Fancybox from "../components/fancybox"
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import "../styles/theme.min.css"
import Seo from "../components/seo"


const links = [
  {
    text: "Tutorial",
    url: "https://www.gatsbyjs.com/docs/tutorial",
    description:
      "A great place to get started!!!!!!",
  }
]

const samplePageLinks = [
  {
    text: "Page 2",
    url: "page-2",
    badge: false,
    description:
      "A simple example of linking to another page within a Gatsby site",
  },
  { text: "TypeScript", url: "using-typescript" }
]

const moreLinks = [
  { text: "Join us on Discord", url: "https://gatsby.dev/discord" },
  {
    text: "Documentation",
    url: "https://gatsbyjs.com/docs/",
  }
]

const utmParameters = `?utm_source=starter&utm_medium=start-page&utm_campaign=default-starter`

export default function IndexPage() {

  const [isOverlayVisible, setIsOverlayVisible] = useState(false)

  const handleToggleOverlay = () => {
    setIsOverlayVisible(!isOverlayVisible)
  }
  
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 550, min: 0 },
    items: 1
  }
};
  return (
     <Layout>
	<Carousel 
  swipeable={true}
  infinite={true}
  autoPlay={true}
  autoPlaySpeed={3000}
  customTransition="all .5"
  transitionDuration={500}  
  responsive={responsive}
  >
	<div>
    <div className="carousel-container">
      <div className="group-5-about-us" >
      <div className="connecting-the-blockchain-together" >Connecting the Blockchain Together. </div>
      <p className="about-us-carousel">At Boston Blockchain Association, we’re promoting the <b>adoption and growth of blockchain technology</b> by connecting innovators, thought leaders, institutions, and market leaders together -- through education, networking, and collaboration.<br /><br /><Fancybox
  options={{
    Carousel: {
      infinite: false,
    },
  }}
><a href="https://www.youtube.com/watch?v=sZf-bekqwKo" data-fancybox><button className="btn-new-hp">Watch Video</button></a></Fancybox></p>      
      </div> 
        <img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1700656750/carousel/khfqm5pkqghahyhyblh1.jpg" className="group-3" />
    </div>
    </div>
	<div>
    <div className="carousel-container">
      <div className="group-5-about-us" >
      <div className="connecting-the-blockchain-together" >Connecting the Blockchain Together. </div>
      <p className="about-us-carousel">At Boston Blockchain Association, we’re promoting the <b>adoption and growth of blockchain technology</b> by connecting innovators, thought leaders, institutions, and market leaders together -- through education, networking, and collaboration.<br /><br /><Fancybox
  options={{
    Carousel: {
      infinite: false,
    },
  }}
><a href="https://www.youtube.com/watch?v=sZf-bekqwKo" data-fancybox><button className="btn-new-hp">Watch Video</button></a></Fancybox></p>      
      </div> 
        <img src="https://res.cloudinary.com/dfwexsly7/image/upload/w_2880/v1705618486/home/bba-home-page-4_dfo1cx.jpg" className="group-3" />
    </div>
    </div>
    <div>
    <div className="carousel-container">
      <div className="group-5-about-us" >
      <div className="connecting-the-blockchain-together" >Connecting the Blockchain Together. </div>
      <p className="about-us-carousel">At Boston Blockchain Association, we’re promoting the <b>adoption and growth of blockchain technology</b> by connecting innovators, thought leaders, institutions, and market leaders together -- through education, networking, and collaboration.<br /><br /><Fancybox
  options={{
    Carousel: {
      infinite: false,
    },
  }}
><a href="https://www.youtube.com/watch?v=sZf-bekqwKo" data-fancybox><button className="btn-new-hp">Watch Video</button></a></Fancybox></p>      
      </div> 
        <img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1704473976/carousel/head_Home_Page_02_algpqo.jpg" className="group-3" />
    </div> 	
    </div> 	
	<div>
    <div className="carousel-container">
      <div className="group-5-about-us" >
      <div className="connecting-the-blockchain-together" >Connecting the Blockchain Together. </div>
      <p className="about-us-carousel">At Boston Blockchain Association, we’re promoting the <b>adoption and growth of blockchain technology</b> by connecting innovators, thought leaders, institutions, and market leaders together -- through education, networking, and collaboration.<br /><br /><Fancybox
  options={{
    Carousel: {
      infinite: false,
    },
  }}
><a href="https://www.youtube.com/watch?v=sZf-bekqwKo" data-fancybox><button className="btn-new-hp">Watch Video</button></a></Fancybox></p>      
      </div> 
        <img src="https://res.cloudinary.com/dfwexsly7/image/upload/w_2880/v1705618485/home/bba-home-page-3_rolqay.jpg" className="group-3" />
    </div> 	
    </div> 	  
	<div>
    <div className="carousel-container">
      <div className="group-5-about-us" >
      <div className="connecting-the-blockchain-together" >Connecting the Blockchain Together. </div>
      <p className="about-us-carousel">At Boston Blockchain Association, we’re promoting the <b>adoption and growth of blockchain technology</b> by connecting innovators, thought leaders, institutions, and market leaders together -- through education, networking, and collaboration.<br /><br /><Fancybox
  options={{
    Carousel: {
      infinite: false,
    },
  }}
><a href="https://www.youtube.com/watch?v=sZf-bekqwKo" data-fancybox><button className="btn-new-hp">Watch Video</button></a></Fancybox></p>      
      </div> 
        <img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1704473977/carousel/head_Home_Page_03_qpspbj.jpg" className="group-3" />
    </div> 	
    </div> 	
	<div>
    <div className="carousel-container">
      <div className="group-5-about-us" >
      <div className="connecting-the-blockchain-together" >Connecting the Blockchain Together. </div>
      <p className="about-us-carousel">At Boston Blockchain Association, we’re promoting the <b>adoption and growth of blockchain technology</b> by connecting innovators, thought leaders, institutions, and market leaders together -- through education, networking, and collaboration.<br /><br /><Fancybox
  options={{
    Carousel: {
      infinite: false,
    },
  }}
><a href="https://www.youtube.com/watch?v=sZf-bekqwKo" data-fancybox><button className="btn-new-hp">Watch Video</button></a></Fancybox></p>      
      </div> 
        <img src="https://res.cloudinary.com/dfwexsly7/image/upload/w_2880/v1705618484/home/bba-home-page-7_eor6hx.jpg" className="group-3" />
    </div> 	
    </div> 		
	<div>
    <div className="carousel-container">
      <div className="group-5-about-us" >
      <div className="connecting-the-blockchain-together" >Connecting the Blockchain Together. </div>
      <p className="about-us-carousel">At Boston Blockchain Association, we’re promoting the <b>adoption and growth of blockchain technology</b> by connecting innovators, thought leaders, institutions, and market leaders together -- through education, networking, and collaboration.<br /><br /><Fancybox
  options={{
    Carousel: {
      infinite: false,
    },
  }}
><a href="https://www.youtube.com/watch?v=sZf-bekqwKo" data-fancybox><button className="btn-new-hp">Watch Video</button></a></Fancybox></p>      
      </div> 
        <img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1704473977/carousel/head_Home_Page_04_g1trsl.jpg" className="group-3" />
    </div> 	
    </div> 
	<div>
    <div className="carousel-container">
      <div className="group-5-about-us" >
      <div className="connecting-the-blockchain-together" >Connecting the Blockchain Together. </div>
      <p className="about-us-carousel">At Boston Blockchain Association, we’re promoting the <b>adoption and growth of blockchain technology</b> by connecting innovators, thought leaders, institutions, and market leaders together -- through education, networking, and collaboration.<br /><br /><Fancybox
  options={{
    Carousel: {
      infinite: false,
    },
  }}
><a href="https://www.youtube.com/watch?v=sZf-bekqwKo" data-fancybox><button className="btn-new-hp">Watch Video</button></a></Fancybox></p>      
      </div> 
        <img src="https://res.cloudinary.com/dfwexsly7/image/upload/w_2880/v1705618484/home/bba-home-page-6_wpqgag.jpg" className="group-3" />
    </div> 	
    </div> 	
	</Carousel>
     <div className="events-container">
  <div className="container-noflex">
    <div className="mission-container">
  <div className="our-mission">Our Mission</div>
  <div className="our-mission-content">The mission of the BBA is to foster a <b>thriving community around blockchain</b> and related technologies by supporting educational programs, inspiring innovation, and empowering ecosystem participants with valuable resources and insights.</div>  
  <div className="mission-btn">
      <a href="/resources" title="Resources"><div className="become-a-member">See Our Resources</div></a>
  </div> 
  </div>
<div className="group-8">
  <img className="events-3-1" src="https://res.cloudinary.com/dfwexsly7/image/upload/v1699650617/newdesign/pvskis2ax6uwswbzdjta.png" /><img className="events-13" src="https://res.cloudinary.com/dfwexsly7/image/upload/v1699650617/newdesign/yze1fs2o88wozismgs5c.png" /><img className="events-7-1" src="https://res.cloudinary.com/dfwexsly7/image/upload/v1699650617/newdesign/e8ki7ofwxfsi5uygkkfd.png" />
</div>
</div>
</div>
     <div className="rectangle-7" > 
<div className="our-partners-and-sponsors" >Our Partners and Sponsors</div> 
 <div className="over-3-500-participants-from-the-world-s-leading-companies" >Over 3,500 participants from the world's leading companies: </div>
  <div className="partner-sponsor-carousel">
    <a href="https://www2.deloitte.com/us/en.html" target="_blank"><img className="our-members-sponsors" src="https://res.cloudinary.com/dfwexsly7/image/upload/v1704743736/branding/new%20logos/deloitte_qb0dcy.png" /></a>  
    <a href="https://ripple.com/" target="_blank"><img className="our-members-sponsors" src="https://res.cloudinary.com/dfwexsly7/image/upload/v1704743803/branding/new%20logos/Ripple_logo.svg_ocj9ap.png" /></a>
    <a href="https://algorandtechnologies.com/" target="_blank"><img className="our-members-sponsors" src="https://res.cloudinary.com/dfwexsly7/image/upload/v1704743717/branding/new%20logos/algorand-foudation-logo_vii5eg.png" /></a>
	  <a href="https://www.ropesgray.com/en" target="_blank"><img className="our-members-sponsors" src="https://res.cloudinary.com/dfwexsly7/image/upload/v1704743710/branding/new%20logos/ropes-and-gray_aalhol.png" /></a> 
  </div>
</div>
<div className="rectangle-9" >
    <img className="events-1-4" src="https://res.cloudinary.com/dfwexsly7/image/upload/v1699650618/newdesign/sb1ecygu2tjbastdmq0q.png" /> 
    <div className="rectangle-10" > 
      <div className="convener-connector-catalyst" >Convener. Connector. Catalyst.</div> 
      <div className="convener-connector-catalyst-content" >As blockchain and related technologies transform every aspect of our world, we hold networking events and learning opportunities for our members to learn how <b>blockchain can solve real-world problems</b>.</div> 
      <div className="mission-btn">
        <a href="/events" title="Resources"><div className="become-a-member">See Our Events Calendar</div></a>
      </div>
    </div> 
  </div> 
     <div className="rectangle-8" >
    <div className="two-column">
  <div className="column">
    <h3 className="col1-h3">3,500+</h3>
    <h5 className="h5-subheader">Meetup members</h5>
  </div>
  <div className="column">
    <h3 className="col2-h3">2,500+</h3>
    <h5 className="h5-subheader">Email subscribers</h5>
  </div>
</div>
<div className="two-column">
  <div className="column">
    <h3 className="col3-h3">175+</h3>
    <h5 className="h5-subheader">Average event attendees</h5>
  </div>
  <div className="column">
    <h3 className="col4-h3">3,000+</h3>
    <h5 className="h5-subheader">2023 attendees</h5>
  </div>
</div>
  </div>      
  <div className="container-noflex-events">
        <div className=" who-we-are-new-volunteer-cta-new">
          <div className="who-we-are-new-volunteer-content-new">
            <h2>Join Us</h2>
            <p>To get notified about all the blockchain events in Boston (BBA and beyond), sign up for our weekly newsletter.</p>
          </div>
          <div className=" who-we-are-new-volunteer-img-hand">
            <img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1701526498/events/1000_F_361449961_i1QO8dNVVvwzGHdEFI1j7f67GbBWHZXq_1_xequcv.png" />
          </div>         
         </div>  
         <div className="newsletter-container-events">
      <h3 className="subscribe-to-the-newsletter">Subscribe to the Newsletter</h3>
    <div className="subscribe-to-the-boston-blockchain-association-newsletter-and-get-notified-on-the-latest-blockchain-events-job-openings-opportunities-and-more" >Subscribe to the Boston Blockchain Association newsletter and get notified on the latest blockchain events, job openings, opportunities, and more. </div>
      <div className="newsletter-form-container">
	  	  <NewsletterGen />
	  </div>
    </div>         
    </div>  
    </Layout>
  );
}
